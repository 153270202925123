cf-map-layer-selector-legend {

  .right-bottom{

    top: 44px!important;

  }

  .popover-content{

    width: 175px;
    height: 277px;
    background-color: #fff !important;

  }

  img.ndvi{

    width: 175px;
    height: 257px;

  }

}
