.calendar-week {

	width: 100%;
	margin-top: 20px;

	thead {

		th {

			width: (100% / 7);
			padding: 10px;
			font-weight: normal;
			font-size: 14px;

		}

	}

	tbody {

		td {

			border: 1px solid #ccc;
			vertical-align: top;
			padding: 10px;
			height: 400px;

		}

	}

}