.form {

	&-sup {

		display: inline-block;
		padding: 8px 0;
		vertical-align: top;

		& + .form-sup {

			margin-left: 20px;

		}

	}

}

fieldset {

	&.styled {

		border: 1px solid #eeeeee;
		padding: 30px 20px 20px;
		margin-bottom: 40px;

		legend {

			border: 0;
			background: none;
			//display: inline-block !important;
			width: auto;
			margin: 0 auto;
			padding: 0 20px;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;

		}

	}

}