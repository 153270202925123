/*
Hard override of metarial styles - until resdesigned
*/

.nav-tabs {

  li {

    color: @brand-primary !important;
    background-color: @body-bg;
    border-bottom: 1px solid #ddd !important;

    a, a:hover {
      color: @brand-primary !important;
    }

    &.active {

      color: @body-bg !important;
      background-color: @brand-primary;
      border-radius: 3px 3px 0 0;

      a, a:hover {
        color: @body-bg !important;
        background-color: @brand-primary;
      }

    }

  }

  background-color: @body-bg;
  border-bottom: 1px solid #ddd !important;

}