#pasture {

  .input-group {

      margin: 0;
      width: 100%;

  }

  .loader {

    min-height: 350px;

    .uil-rolling-css {

      margin-top: 10%;

    }

  }

}