cf-por-batch {

  .btn-group{

    width: 100%;

  }

  .panel-heading{

    a.remove-batch {

      color: #CB4D41;

    }

    a.batch-detail {

      padding: 8px 30px;
      padding-left: 5px;
      display: inline-block;
      vertical-align: middle;
      line-height: @line-height-base;

    }

    .input-group-btn {

      a.add-batch {

        color: #5cb85c;

      }

      a.edit-batch {

        color: #ec971f;

      }

    }

  }


 /* .batch-edit-panel {

    margin: 10px 0;


    .download-btn{

      button {

        color: #337ab7;

      }

    }

  }
*/
}