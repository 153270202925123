.map {

		position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

  &-small {

	margin-bottom: 20px;

	img {

	  width: 100%;
	  height: auto;

	}

  }

  .sidebar-mini & {

	left: 60px;

  }

	&-controls {

		position: absolute;
		top: 85px;
		right: 15px;

		&-horizontal {

			top: 15px;
			white-space: nowrap;
			font-size: 0;

			a {

				display: inline-block !important;
				font-size: 16px;
				border-top: 0;
				border-left: 1px solid @gray;
			}

		}

		&-group {

			background: @gray-dark;
			margin-bottom: 20px;

		}


		a {

			display: block;
			padding: 12px 15px;
			color: #ffffff;
			text-decoration: none;
			border-top: 1px solid @gray;
			font-size: 16px;

			&:hover {

				color: #fff;
				text-decoration: none;
                background-color: #444444;

			}
		}

	}

  &-navbar {

	position: absolute;
	top: 15px;
	left: 15px;
	background: @gray-dark;
	list-style: none;
	padding: 0;
	margin: 0;

	&-li {

	  float: left;
	  position: relative;

	  &:last-child {

		a {

		  border-right: 0;

		}

	  }

	  > a,
	  > a:visited,
	  > a:active {

		display: block;
		padding: 12px 15px;
		color: #ffffff;
		text-decoration: none;
		border-left: 1px solid @gray;

		&:hover {

		  color: #fff;
		  text-decoration: none;

		}

	  }

	  &.active {

		> a {

		  background: #000;
		  color: #fff;
		}
	  }

	  .toggle-box-item {

		list-style: none;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 100%;
		white-space: nowrap;
		background: @gray-dark;
		border: 1px solid @gray;
		border-bottom: 0;


		ul {

		  list-style: none;
		  padding: 0;
		  margin: 0;

		  > li {

			font-size: 14px;
			border-bottom: 1px solid @gray;

			label {

			  display: block;
			  cursor: pointer;
			  margin: 0;


			  &:hover {

				color: #fff;

			  }

			  img {

				margin-right: 10px;
			  }

			}

			> ul {

			  list-style: none;
			  margin: 0;
			  padding: 0;

			  > li {

				label {

				  display: block;
				  cursor: pointer;
				  margin: 0;

				  span {

					padding-top: 6px;
					padding-bottom: 6px;
					padding-left: 96px;

				  }

				  &:hover {

					color: #fff;

				  }

				  img {

					margin-right: 10px;
				  }

				  input[type="checkbox"] {

					left: 72px;

				  }

				}

			  }

			}

		  }

		}

		&.columns-2 {

		  width: 600px;

		  > ul {

			float: left;
			width: 50%;

			&:nth-child(odd) {

			  border-right: 1px solid @gray;
			}

		  }

		}

		.map-search {

		  width: 350px;
		  left: auto;
		  right: 0;

		  fieldset {

			padding: 15px;

		  }

		}

		.map-edit {

		  // width: 350px;
		  left: auto;
		  right: 0;
		  font-size: 0;

		  a {

			display: inline-block;
			width: 50px;
			height: 50px;
			font-size: 20px;
			text-align: center;
			line-height: 50px;

			& + a {

			  border-left: 1px solid @gray;
			}
		  }

		}

	  }

	}

  }

}

#mapActionButtons {

  position: absolute;
  z-index: 100000;
  right: 300px;

  .map-controls {

    z-index: 10000;

    a {

      background-color: @gray-dark;
      border: 1px solid @gray;

    }

  }

}
