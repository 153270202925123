.autocomplete {

	&-wrapper {

		position: relative;
		z-index: 200;

	}

	&-window {

		position: absolute;
		top: 100%;
		left: 16px;
		list-style: none;
		background: #ffffff;
		box-shadow: 0 0 2px rgba(0,0,0,0.3);
		border: 1px solid #ccc;
		margin: 10px 0 0 0;
		padding: 0;
		border-radius: 4px;
		list-style-position: outside;
		min-width: 300px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(10px);
		transition: all 0.3s ease-in-out;

		&-active {

			opacity: 1;
			visibility: visible;
			transform: translateY(0);

		}

		&:after,
		&:before {

			bottom: 100%;
			left: 20px;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;

		}

		&:after {
			border-color: rgba(136, 183, 213, 0);
			border-bottom-color: #ffffff;
			border-width: 6px;
			margin-left: -6px;
		}

		&:before {
			border-color: rgba(194, 225, 245, 0);
			border-bottom-color: rgba(0,0,0,0.12);
			border-width: 9px;
			margin-left: -9px;
		}

		li {

			padding: 8px 15px;
			cursor: pointer;

			&:first-child {

				border-radius: 4px 4px 0 0;

			}

			&:last-child {

				border-radius: 0 0 4px 4px;

			}

			&:hover {

				background: #f7f7f7;
				color: @brand-primary;

			}

			& + li {

				border-top: 1px solid #ccc;

			}

		}

	}

}