
// features
@import "classifiers";
@import "action-eph";
@import "actions-other";
@import "action";
@import "pors";
@import "map-popup";
@import "cf-map-mouse-coords";
@import "cf-map-toolbar";
@import "spinner";
@import "error";
@import "link";
@import "modal";
@import "map-navbar";
@import "map-tooltip";
@import "cf-map-feature-buffer";
@import "map-draw-parcel";
@import "cf-map-layer-selector-legend";
@import "pasture";
@import "cf-action-list-item-header";
@import "loader";

@import "table";




//TODO
/****************************************************************************/
a.disabled-link{
  text-decoration: none;
  color: #ccc;
  cursor: default;
}

.link-add-holder{
  margin: 15px 0px;
}

a.link-add{
  cursor: pointer;
}


/****************************************************************************/
.row {
  .col-3ths {

    width: 33.333%;
    float: left;

  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

.col-sm-1 a.areal-remove{
  padding-left: 0px;
  padding-right: 0px;
}

.input-number-plus,
.input-number-minus{
  cursor: pointer;
}


.edit-focus{
  border-color: #df0006;
  box-shadow: 0 0 3px 1px #df0006;
  -moz-box-shadow: 0 0 3px 1px #df0006;
  outline: 0 none;
}


/*******************************************************************/


.area-details-header{

  .areal-remove{

    padding: 1px;

  }

  .btn-xs, .btn-group-xs > .btn{

    font-size: 12px;

  }

}


/************************ parcel *****************************/




.table form .row .form-control {
  font-size: 14px;
  font-weight: normal;
}

.table tbody .alert{
  margin-top: 24px;
}





#havarijni-plan,
#pors {

  .btn-file {
    position: relative;
    overflow: hidden;
    //margin-bottom: 20px;
  }
  .btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
}

/***************************** zones *******************************************/

#zones .btn-lg, .btn-group-lg > .btn {
  width: 100%;
}

/***************************** validations *******************************************/


/***********/
cf-sortable-th{
  cursor: pointer;
}

