select.no-selection{
  color: @mdb-input-placeholder-color!important;
}

select {

  option{
    color: #555555;
  }

  option.select-placeholder{
    color: @mdb-input-placeholder-color!important;
  }

}

