.modal {

	.modal-content, .modal-header, .modal-body {
		background-color: #FFF;
	}

	&-dialog {

		min-width: 340px;

		//medium and big screens
		@media (min-width: @screen-sm-min) {
			width: @screen-sm;
		}

		//small screens
		@media (max-width: @screen-xs-max) {
			width: auto;
		}

	}

	&-footer {

		text-align: center;

	}

}

.modal-error500 {
	color: #fff
}

.modal-error500:hover, 
.modal-error500:active, 
.modal-error500:focus {
	color: #ccc
}