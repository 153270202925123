@nav-bar-height: 54px;

.manager {

  //the collapsed nav will snap to the top
  @media (max-width: @screen-xs-max) {
    margin-top: -50px;
  }

  div > a {
    text-decoration: none;
    outline: none;
  }

  .panel-heading {
    padding: 0 !important;
    height: @nav-bar-height !important;
  }

  .navbar {
    height: @nav-bar-height !important;
    background-color: @cf-brand-theme;

    .navbar-collapse {

      position: relative;
      z-index: 1;

      .navbar-nav {
        margin-top: 0;
      }

      > ul {
        background-color: @cf-brand-theme;

        > li > a {
          padding-top: 15px;
          padding-bottom: 15px;
        }

        > li:hover {
          background-color: lighten(@cf-brand-theme, 8%);
        }
      }

    }

    .dropdown-menu li > a:hover{
      color: @cf-brand-theme;
    }
  }

  .form-license {
    min-height: 250px;
    overflow-y: scroll;
  }

  .welcome-msg{

    padding-top: 20px;
    padding-bottom: 20px;

  }

  .farm-import{

    padding-bottom: 40px;

    .farm-import-shp {

      padding: 10px;

    }

    .farm-import-shp:hover {

      @shadow: 0 0 3px #ccc;

      box-shadow: @shadow;
      -moz-box-shadow: @shadow;
      -webkit-box-shadow: @shadow;

    }

  }

}

