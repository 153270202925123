.on-off {

	border-radius: 60px;
	width: 90px;
	height: 42px;
	display: inline-block;
	position: relative;
	line-height: 24px;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 14px;
	margin-right: 15px;

	input[type="checkbox"] {

		display: none;

	}

	input[type="checkbox"] {

		& + span {

            background-color: #2c2c2c;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			padding: 4px 10px;
			border-radius: 50px;
			color: #2c2c2c;
			transition: background 0.2s ease-in-out;

			&:before {

				content: '';
				display: block;
				width: 32px;
				height: 32px;
				background: @brand-danger;
				position: absolute;
				top: 50%;
				margin-top: -16px;
				left: 4px;
				border-radius: 50px;
				border: 0;
				z-index: 10;
				transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, background 0.2s ease-in-out;

			}

			&:after {

				content: attr(data-content);
				display: block;
				position: absolute;
				top: 0;
				line-height: 42px;
				right: 14px;
				color: #fff;
				z-index: 5;
				transition: right 0.2s ease-in-out, margin-right 0.2s ease-in-out;

			}

		}

		&:checked + span {

            background-color: #2c2c2c;
			color: #fff;

			&:before {

                content: '';
				left: 100%;
				margin-left: -36px;
				background: @brand-success;

			}

			&:after {

				content: attr(data-content);
				right: 100%;
				margin-right: -42px;

			}

		}

        &:disabled + span {
            background-color: #cdcdcd;
        }

	}

}