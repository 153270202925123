#parcels {

  height: ~"calc(100% - 60px)";

  .col-lg-9{

    .table-responsive{

      height: 100%;
      max-height: 100%;

    }

    .parcels-operations{

      margin: 5px 0 0 0;

    }

  }

  .parcel-search {

    margin-top: 10px;

  }

  table{

    overflow: hidden;

  }

  .equal-height {

    height: 65px;

  }

  #tbody-loader {

    height: 350px;

    .uil-rolling-css {

      margin: 0 auto;

    }

  }


}