.sort {

	color: inherit;
	text-decoration: none;
	padding-right: 25px;
	position: relative;
	display: inline-block;

	&-active {

		color: @brand-primary;

	}

	&:hover {

		text-decoration: none;

	}

	&-icon {

		position: absolute;
		display: inline-block;
		width: 20px;
		height: 16px;
		top: 50%;
		right: 0;
		margin-top: -8px;

		span {

			position: absolute;
			left: 0;
			line-height: 8px;
			width: 100%;
			height: 8px;
			font-size: 10px;

			&.sort-off {

				color: #ccc;

			}

			&.sort-up {

				top: 0;

			}

			&.sort-down {

				top: 8px

			}

		}


	}

}