.append {

	display: table;
	border-collapse: separate;
	width: 100%;

	&-main {

		display: table-cell;
		float: left;
		width: 100%;

		.input {

			width: 100%;

		}

	}

	&-side {

		width: 40%;
		white-space: nowrap;
		vertical-align: middle;
		display: table-cell;
		position: relative;

		&:first-child {

			select {

				// -webkit-appearance: none;
		      -webkit-border-radius: 0;
		      border-radius: 0;

			}

		}

	}

}