.map-navbar{

  z-index: 1000;
  background-color: unset;
  color: #fff;

  li {

    background-color: @gray-dark;

    > a:focus {

      outline: 0;

    }

    .toggle-box-item {

      ul {

        > li {

          label{

            display: inline;
            cursor: pointer;
            //margin: 15px;
            padding: 13px;
            border-right: 1px solid @gray;

            input[type="checkbox"] {

              margin: 15px 0px;
              position: relative;
              left: 3px;
              top: 1px;

            }

          }

          span {

            padding: 15px;
            cursor: pointer;
            font-weight: 600;

          }

          span:hover{

            color: #fff;

          }

        }

        > li.active{

          background-color: #cb4d41;
          color: #fff;

        }

      }

      .map-edit {

        a {

          color: #9d9d9d;

        }

        a:hover {

          color: #ffffff;

        }

        button {

          display: inline-block;
          width: 50px;
          height: 50px;
          font-size: 20px;
          text-align: center;
          color: #333;
          margin: 5px;
          margin-right: 10px;
          white-space: nowrap;
          background-color: #eee;
          background-image: -webkit-linear-gradient(#fcfcfc, #eee);
          background-image: linear-gradient(#fcfcfc, #eee);
          border: 1px solid #d5d5d5;
          border-radius: 10px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-appearance: none;

        }

        button:hover {

          color: #cb4d41;

        }

        button.active {

          color: #cb4d41;

        }

      }

    }

    &:hover {
      background-color: #444444;
    }

  }

  &.editor-navbar {

    left: 300px;

  }

  .actions {

    a.active {

      color: #cb4d41;

    }

  }
}