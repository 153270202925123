#zones {

  .zone-operations{

    margin: 10px 0;
    padding: 0;

    .btn{

      margin: 0;

    }

  }

  #tbody-loader {

    .uil-rolling-css {

      margin: 0 auto;

    }

  }

}