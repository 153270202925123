#other-action-step2{

  .date {
    width: 100%;
  }

  .loader {

    min-height: 350px;

    .uil-rolling-css {

      margin-top: 10%;

    }

  }

}