#cf-mouse-coords-holder {

  position: absolute;
  bottom: 0px;
  left: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-top-right-radius: 10px;
  box-shadow: -3px 3px 5px rgba(255, 255, 255, 1);
  color: #ffffff;
  font-size: 12px;
  z-index: 1000;

  .cf-mouse-coords {

    .coord{

      padding-right: 5px;

    }

    select {

      color: #000000;
      padding: 0;
      display: inline;
      margin-right: 5px;
      line-height: 1.52857143;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    }

    .toggle-mouse-coords{

      color: #ffffff;
      margin: 0px 3px;
      float: right;
      position: relative;
      top: 2px;

    }

    .toggle-mouse-coords:hover {

      color: #ccc;

    }

  }

}

.mininal-height{

  min-width: 390px;

}
