.table {

  .col-checkbox {

    height: 50px;

    &-dropdown {

      .dropdown {

        button, div {

          padding: 10px 10px;
          border: 0;
          background: none;
          outline: none !important;
          cursor: pointer;

        }

      }

    }

  }
}