.parameters {

	text-align: center;
	margin: 0;
	font-size: 14px;


	strong {

		color: #000;

	}

	.col-md-4 {

		padding: 0;
		border-top: 1px solid #e5e5e5;

		& + .col-md-4 {

			border-left: 1px solid #e5e5e5;

		}

	}

	&-item {

		padding: 20px;
		font-size: 18px;

	}

}