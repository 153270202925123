.form-control.ng-invalid.ng-dirty,
.form-control.ng-invalid.ng-pristine{

  border-color: #a94442;
  //-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

}

label.has-error.control-label {

  .has-error .control-label;

}

.form-group label.has-error{

  margin: 0;

}