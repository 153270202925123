.error-page{

  color: @brand-primary;

  h1, h3, h5{

    text-align: center;
    font-weight: bold;

  }

  h1{

    font-size: 90px;

  }

  h3{

    font-size: 60px;

  }

  h5{

    font-size: 38px;

  }

  .glyphicon-holder{

    text-align: center;

    span{

      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 60px;

    }

  }

}