#license-tabset {

  .nav-tabs {

    background-color: transparent;
    border-bottom: 0!important;
    text-align: center;

    li, li.active{

      display:inline-block;
      background-color: transparent!important;
      border-bottom: 0!important;
      float: none!important;

      a, a:hover{

        color: #CB4D41 !important;

        p {

          font-size: 14px;
          color: @mdb-input-placeholder-color;

          .badge {

            background-color: @mdb-input-placeholder-color;

          }

        }

        p.active-tab-badge{

          color: @cf-brand-theme;

          .badge {

            background-color: @cf-brand-theme;

          }

        }

      }

    }

  }

  #license-text{


    #license-text-holder{

      max-height: 250px;
      overflow-y: auto;
      text-align: center;

    }

  }

}