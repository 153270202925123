#stores-move {

  .date {

    width: 100%;

  }

}

#stores-stock {

  .date {

    width: 100%;

  }

}

#stores {

  .negative-amount {
    background-color: #f2dbd8;
  }

  #tbody-loader {


    .uil-rolling-css {

      min-height: 300px;
      margin: 5% auto 0 auto;

    }

  }

}

.stores-checkbox {
  margin-bottom: 5px;
}

