/*
Hard override of metarial styles - until resdesigned
*/

tr.table-thead-row-filter {

  th {
    padding-top: 5px !important;
    padding-bottom: 0 !important;
    background-color: transparent !important;
  }

  .btn {
      margin-top: 10px !important;
  }

}