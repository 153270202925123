.table {

	font-size: 12px;


	&-parent {

		border: 0;
		border-top: 1px solid #dddddd;

	}

	th, td {

		vertical-align: middle !important;
		text-align: left;

		.file-icon {

			display: inline-block;
			text-decoration: none;
		}

		&.col-parent {

			padding: 0 !important;
			border: 0 !important;

			.table {

				width: 100%;
				margin: 0;
				border: 0 !important;

				thead {

					th {

						border: 1px solid #dddddd;
						border-bottom: 2px solid #dddddd;
						border-top: 0;

					}

				}

				tbody {

					td, th {

						border: 1px solid #dddddd;

					}

				}

				> tbody > tr:first-child td {

					border-top: 0;

				}

			}

		}

	}

	tbody {

		tr.row-added {

			> td {

				> .input-group {

					width: 100%;
					max-width: 100%;
					margin: 0;

				}

			}

		}

		th, td {

			padding: 5px 15px !important;
			font-size: 14px;

			input.form-control {

				padding: 0 10px;
				height: 30px;
				font-size: 14px;
			}

			.input-name[readonly] {

				border-color: transparent;
				background: transparent;
				box-shadow: none;
				padding-left: 0;

			}

			select.form-control {

				padding: 0 10px;
				height: 30px;
				font-size: 14px;
			}

			.input-group {

				width: 100%;
				max-width: 200px;
				margin-top: 10px;

				&-clean {

					width: 100%;
					max-width: 100px;
					margin-top: 10px;
					display: inline-block;

					.form-control {

						width: 100%;
					}

				}

				&-addon {

					font-size: 12px;

				}
			}

			.btn {

				padding: 3px 10px;
				font-size: 15px;

			}

			.form-inline {

				padding: 10px 0 20px 0;

				label {

					margin-right: 10px;

				}

			}

		}

		.row-detail {

			> td {

				background: darken(#f7f7f7,1%);

			}

		}

	}

	thead {

		th {

			background: #f7f7f7;
			padding: 10px 15px !important;
		}

		.table-thead-row {

			&-main {

				th {

					background: @brand-primary;
					font-size: 16px;
					padding: 10px 15px;
					text-transform: uppercase;
					text-align: left;
					color: #fff;
				}

			}

			&-filter {

				th {

					padding: 10px 15px;
					background: darken(#dddddd,5%);

					.form-group {

						margin: 0;

					}

					.row {

						margin-left: -5px;
						margin-right: -5px;
					}

					[class*="col-md"] {

						padding-left: 5px;
						padding-right: 5px;
					}
				}

			}
		}

	}

	em {
		display: block;
	}

	.col-checkbox {

		text-align: center;
		width: 50px;

		&-dropdown {

			padding: 0 !important;

			.dropdown {

				button {

					padding: 10px 10px;
					border: 0;
					background: none;
					outline: none !important;

				}

				&.open {

					background: #ccc;

				}

			}

		}

	}

	form {

		.form-control {

			font-size: 12px;

		}

	}

	&-parameters {

		width: 100%;
		border: 1px solid #ccc;
		margin-bottom: 20px;

		tr {

			td {

				border: 1px solid #ccc;
				padding: 15px 10px !important;
				text-align: center;

			}

		}

	}

	&-clean {

		margin: 0 !important;

		tbody {

			th, td {

				border: 0 !important;
				padding-left: 0 !important;
				padding-right: 0 !important;
				font-size: 14px;

			}

		}

	}

}