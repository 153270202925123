.help {

	&-trigger {

		cursor: pointer;
		color: @brand-primary;
		position: relative;
		display: inline-block;
		z-index: 9999;

		.glyphicon {

			margin: 0 !important;
		}

		&:hover {

			.help-content {

				display: block;

			}
		}

	}

	&-content {

		background: @brand-primary;
		padding: 10px;
		font-size: 12px;
		color: #fff;
		border-radius: 3px;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		display: inline-block;
		float: left;
	    max-width: 200px;
	    width: 500px;
	    display: none;

	    &:before {

	    	top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-top-color: @brand-primary;
			border-width: 6px;
			margin-left: -6px;

	    }

	}



}