#zone {

  .zone-operations{

    margin: 10px 0;
    padding: 0;

    .btn{

      margin: 0;

    }

  }

  #new-element .form-group{
    width: 100%
  }

  #new-element .form-control{
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 0px;
    resize: none;
  }

  #tbody-loader {

    .uil-rolling-css {

      margin: 0 auto;

    }

  }

}

// new zone
.parcel-textarea{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 0;
  resize: none;
}