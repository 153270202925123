#por {

  input[type='checkbox']{

    display: block;
    margin: auto;

  }

  div#general-info{

    select {

      width: 100%;

    }

  }

  div#active-substances {

    .input-group {

      max-width: none;
      width: 100%;

    }

  }

  table.control-buttons {

    a {

      padding: 1px 5px;
      font-size: 12px;
      width: 60px;

    }

  }

  .app-detail-devider {

    margin: 0px;

  }

  table.crop-table, table.org-table {

    width: 100%;

    div.crop-list, div.org-list {

      max-width: none;
      width: 100%;

      .form-control {

        //height: 30px;

      }

    }

  }

  table.por-dates-info{

    cf-datepicker {

      p{

        margin: 0px;

      }


    }

  }

  div.amount-inputs {

    .form-control {
      width:60%;
      padding: 0;
      padding-left: 10px;
      height: 30px;
      font-size: 14px;
    }

    .unit-select {

      width:40%;
      color: #555555;
      background-color: #eeeeee;
      border: 1px solid #ccc;
      padding-left: 0px;

    }

  }

  div#app-non-dosage-details, div#general-info {

    th {

      width: 60%;

    }

    td {

      width: 40%;
      text-align: center;

    }

    .input-group {

      max-width: none;

      .form-control {

        max-width: none;

      }

    }

    .por-note {

      th {

        width: 20%;

      }

      td {

        width: 80%;

      }

    }

  }

  table.por-note{

    th {

      width: 20%;

    }

    td {

      width: 80%;

      .por-textarea {

        width: 100%;
        height: 100%;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        -moz-border-radius: 5px;
        resize: none;
        padding: 10px;

      }

    }
  }

  .row-glued [class*="col-"]:first-child .panel-default,
  .row-glued [class*="col-"]:first-child .panel-heading {
    border-radius:  0;
    height: 100px;
  };

  .row-glued [class*="col-"]:last-child .panel-default,
  .row-glued [class*="col-"]:last-child .panel-heading {
    border-radius:  0;
    height: 100px;
  }

  div#restriction-distances{

    th {

      width: 60%;

    }

    td {

      width: 40%;

    }

    .input-group {

      max-width: none;

      .form-control {

        max-width: none;

      }

    }



  }

  .uil-rolling-css {

    left: 40%!important;
    top: 200px!important;

  }


}