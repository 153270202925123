#eph {

  .uil-rolling-css {
    left: 45%!important;
    top: 200px!important;
  }

  .material-name-holder {

    -webkit-transition: margin-bottom 0.5s ease-in-out;
    -moz-transition: margin-bottom 0.5s ease-in-out;
    -o-transition: margin-bottom 0.5s ease-in-out;
    transition: margin-bottom 0.5s ease-in-out;

  }

  .no-bottom-margin{

    margin-bottom: 0;

  }

  .bottom-margin{

    margin-bottom: 25px;

  }


  cf-datepicker {

    p.input-group {

      margin: 0;

    }

  }

  cf-action-target-crop {

    .target-crop-warning {

      .alert {

        margin-top: 20px;

      }

    }

  }

  cf-action-eph-application-selector {

    #eph-application-selector-btn {

      //min-height: 400px;

      .centered {

        padding-top: 170px;

      }

    }

    .maxwidth{

      min-height: 400px;

    }

  }

  .por-crop-warning {

    .alert {

      margin-top: 20px;

    }

  }


}