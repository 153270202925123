#cf-map-toolbar {

  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;

  .map-controls {

    top: 170px;
    right: 42px;

    a:active {

      color: #eee;

    }

    .map-search-panel{

      display: none;
      position: absolute;
      right: 45px;
      width: 353px;
      height: 48px;
      padding: 3px;
      background-color: rgba(0, 0, 0, 0.7);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      .form-group {

        margin: 0;

        .form-control {

          width: 100%;
          color: white;

        }

      }

    }

    #move-btns {

      background: none;

      a {

        background-color: @gray-dark;
        position: relative;
        border: 1px solid @gray;

        &:hover {
          background-color: #444444;
        }

      }

      #left {

        right: 24px;
        border-right: 0;
      }

      #right {

        left: 23px;
        border-left: 0;
      }

      #up {

        top: -47px;
        left: 70px;
        border-bottom: 0;

      }

      #down {

        top: 47px;
        right: 72px;
        border-top: 0;
      }

      #home {
        right: 120px;
      }

    }

  }

  .map-controls-horizontal {

    top: 50px;
    right: -79px;

  }

}

.awesome-marker i {
  font-size: 18px;
  margin-top: 8px;
}


