cf-action-list-item-header {

  .form-group {

    margin-bottom: 0;

  }

  .btn-xs {

    font-size: 12px;

  }

}