.checkbox-btn {

	display: block;
	position: relative;

	span {

		display: block;
		padding: 9px 15px 9px 30px;

	}

	input[type="checkbox"] {

		position: absolute;
		top: 50%;
		left: 10px;
		margin: 0;
		transform: translateY(-50%);

		&.high + span {

			background: @brand-primary;
			color: #fff;
		}

		&:focus {

			outline: none;

		}

		&:checked {

			& + span {

				background: darken(#282828,5%);
				color: #fff;

			}

		}

	}

	&-thumb {

		span {

			padding: 9px 15px 9px 72px;

		}

		img {

			position: absolute;
			top: 50%;
			max-width: 30px;
			max-height: 20px;
			left: 10px;
			margin-top: -10px;

		}

		input[type="checkbox"] {

			left: 30px + 20px;

		}



	}

}