dl {

	font-size: 14px;
	margin: 0;
	padding: 5px 0;
	.clearfix();

	dt {

		float: left;
		width: 200px;
		font-weight: normal;

	}

	dd {

		overflow: hidden;
		color: #000;
		font-weight: bold;
	}
}