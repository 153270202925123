.areal,
.machine,
.employee,
.activity,
.feast,
.animal {

	&-simple {

		padding: 10px;
		font-size: 14px;
		margin-bottom: 10px;

		&:last-child {

			margin-bottom: 0;

		}

	}
}

.areal {

	&.opened {

		.areal-value {

			display: none;

		}

	}

}

.machine {

	&.opened {

		.machine-value {

			display: none;

		}

	}

}

.employee {

	&.opened {

		.employee-value {

			display: none;

		}

	}

}

.activity {

	&.opened {

		.activity-value {

			display: none;

		}

	}

}

.feast {

	&.opened {

		.feast-value {

			display: none;

		}

	}

}

.animal {

	&.opened {

		.animal-value {

			display: none;

		}

	}

}