@grid-float-breakpoint:     @screen-md-min;

cf-navbar {

  .navbar {

    .navbar-nav {

      li {

        &.active.dropdown {
          a {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }

        a {

          font-size: 14px;
          padding-left: 10px;
          padding-right: 10px;

        }

      }

      .dropdown-menu {
      
        li {

          a {

            font-size: 14px;

          }

        }

      }

    }


    #nav-farmpicker{

      min-width: 140px;

      li {
        width: 100%;
        a {
          text-align: center;
        }
      }

    }

  }

}



@media screen and (max-width: 1365px) {
  
  cf-navbar {

    .navbar {

      .navbar-nav {

        li {

          a {

            font-size: 12px;
            padding-left: 8px;
            padding-right: 8px;

          }

        }

        .dropdown-menu {
      
          li {

            a {

              font-size: 12px;

            }

          }

        }

      }

    }
    
  }

}


@media screen and (max-width: 1199px) {
  
  cf-navbar {

    .navbar {

      .navbar-brand {
      
        height: 60px;
        padding: 13px 15px;
      
      }

      .navbar-nav {

        li {

          a {

            font-size: 14px;
            padding-left: 17px;
            padding-right: 17px;

          }

        }

        .dropdown-menu {
      
          li {

            a {

              font-size: 14px;

            }

          }

        }

      }

       #nav-farmpicker {

        min-width: 120px;
       
      }
      
    }

  }

}


@media screen and (max-width: 1080px) {

  cf-navbar {

   .navbar {

     .navbar-nav {

       li {

         a {

           padding-left: 15px;
           padding-right: 15px;

         }

       }

     }

   }

 }

}

@media screen and (max-width: 1030px) {

  cf-navbar {

   .navbar {

     .navbar-nav {

       li {

         a {

           padding-left: 13px;
           padding-right: 13px;

         }

       }

     }

   }

 }

}


@media screen and (max-width: @screen-sm-max) {

   cf-navbar {

    .navbar {

      .navbar-nav {

        li {

          a {

            font-size: 16px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;

          }

        }

      }

    }

  }

}
