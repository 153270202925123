
#action {

  .map-controls {

    top: 15px;
    right: 35px;

    z-index: 800;

    a {

      padding: 3px 7px;

    }

  }

  .same-height-panels [class*="panel-default"]{
    min-height: 210px;
  }

  .material-holder {

    margin-bottom: 10px;

  }

  .material-holder:last-child{

    margin-bottom: 0px;

  }

  .table {

    tr {

      th.align-top{

        vertical-align: top !important;

      }

    }

  }

  cf-datepicker {

    p {

      margin: 0px;

    }

  }

  .areal-remove {

    color: #CB4D41;

  }

  .action-detail {

    .form-control {

      width: auto;
      display: inline-block;

    }

  }

  .table {

    tbody {

      td {

        .input-group{

          width: auto;
          max-width: 150px;
          margin-top: 0px;

        }

      }

    }

  }

}