.calendar-month {

	margin-top: 20px;
	width: 100%;

	thead {

		th {

			width: (100% / 7);
			padding: 10px;
			font-weight: normal;
			font-size: 14px;

			&.weekend {

				color: darken(#ccc,20%);

			}

		}

	}

	tbody {

		td {

			height: 150px;
			border: 1px solid #ccc;
			vertical-align: top;
			padding: 10px;

		}

	}

}