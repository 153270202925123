cf-datepicker {

  span {
    position: relative;
    cursor: pointer;
  }

  .help-block.datepicker-error{
    display: block;
    color: @brand-warning !important;
    font-weight: normal;
  }

  .form-control {
    font-weight: normal;
    font-size: 14px;
  }

  .input-group {
      td, th{
        text-align: center;
        padding: 0px !important;
        .btn.btn-sm {
          margin: 5px 0px;
        }
      }
  }

  table {
    tbody {
      td {
        .btn.btn-sm {
          padding: 3px 10px;
          margin: 5px 0px !important;
          font-size: 14px;
        }

      }
    }
  }

}