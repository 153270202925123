#parcel{

  .parcel-operations{

    .btn-group{
      margin: 0;
    }

    .btn{
      margin: 0;
    }

  }



  .parcel-title {

    input{

      background: #fff none repeat scroll 0 0;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: #cb4d41;
      padding: 3px 10px;
      max-width: 250px;

    }

  }

  #parcel-detail-panels {

    .panel-heading {

      font-size: 22px;

    }

  }

  #map-window-parcels{

    cm-leaflet-map {

      min-height: 190px;
      //height: 194px;
      height: 374px;
      width: 100%;
      display: block;
      margin-bottom: 24px;
    }

  }

  #parcel-note {

    .parcel-textarea{
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: 0px;
      resize: none;
    }

  }

  .edit-focus input, .edit-focus select{
    border-color: #df0006;
    box-shadow: 0 0 3px 1px #df0006;
    -moz-box-shadow: 0 0 3px 1px #df0006;
    outline: 0 none;
  }

}

.pagination{
  margin: 0;
}

#parcelChangeSeedModal .form-group{
  margin-bottom: 5px;
}






#parcel-detail-table th{
  max-width: 40%;
}

#parcel {

  #planned-actions {

    cf-action-date-filter {
      .btn-group {
        margin-top: 10px;
      }
    }

    cf-datepicker {

      p {

        margin-bottom: 0px;
      }

    }

    th {

      background-color: transparent;

    }

    .btn-group{

      margin-top: 0;
      margin-bottom: 0;

    }

    .single-button {

      margin-right: 5px;
      margin-top: 0;

    }

  }

  .map-controls {

    top: 15px;
    right: 15px;

    z-index: 800;

    a {

      padding: 3px 7px;

    }

  }

  #tbody-loader {

    .uil-rolling-css {

      margin: 0 auto;

    }

  }

}

#parcel .tab-content .nav-tabs .active a:hover,
#parcel .tab-content .nav-tabs .active a:focus{
  color: #555555
}

.parcel-restriction-list {
  padding: 10px;
}

