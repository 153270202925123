cf-map-feature-buffer {

  .buffer-field {

    width: 150px;
    padding: 3px;

    .form-group {

      padding: 0;
      margin: 0;

      .form-control{

        color: white;
        margin-bottom: 2px;

      }

    }

  }

}