.height {

	&-85 {

		height: 85px;

	}

	&-103 {

		height: 103px;

	}

}