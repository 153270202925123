.modal-sm {

  width: 600px;


  .por-modal{

    h4 {

      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;

    }

  }
}

.modal-header-error {

  border-bottom: 0;
  background-color: @brand-danger!important;
  color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  h3 {

    padding: 15px 0px;

  }

}

.modal-footer-error {

  border-bottom: 0;

}

.zoom-to-coords-error-modal {

  .coords-centered {

    text-align: center;

  }

}