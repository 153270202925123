.navbar-inverse {

	background-color: @navbar-background !important;

	.navbar-brand {

		background: @logo-background;
		color: #fff;
		width: 200px;
		text-align: center;
		display: flex;

		&:hover {

			background: @logo-background;

		}

	}

	.logo {
		max-width: 80%;
	}

	.navbar-nav {

		li {

			a {

				padding-top: 18px;
				padding-bottom: 18px;

			}

		}

	}

	.level {

		&-1 {

			a {

				padding-left: 30px;

			}

		}

		&-2 {

			a {

				padding-left: 40px;

			}

		}

	}

}

.farm-picker-menu {
	max-height: 600px;
	overflow: auto;
}
