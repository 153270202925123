@color-valid: #7cb342;
@color-invalid: #e53935;

cf-farm-import-shp {

  .form-horizontal .control-label {
    padding-top: 15px;
  }

  .form-group label.control-label {
    margin: 0;
  }

  .form-group.is-focused label.control-label:not(.has-error) {
    color: @color-valid;
  }

  .form-group.is-focused .form-control:not(.ng-invalid){
    background-image: linear-gradient(@color-valid, @color-valid), linear-gradient(#D2D2D2, #D2D2D2);
  }

  .form-group input[type=file]{
    width: 80%;
  }

  .form-group input[type=file].full-width{
    width: 100%;
  }

  #farm-import-lpis-shp-err{

    display: auto;

  }

}
