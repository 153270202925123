.jumbotron {

	margin-bottom: @jumbotron-padding;
	padding: @jumbotron-padding 0;
	background: none !important;
	border-bottom: 1px solid #eee;

	h1,
	.h1 {

		margin-bottom: (@jumbotron-padding);
		font-size: 36px;

	}


	p {

		margin-bottom: (@jumbotron-padding * 1.5);
		font-size: 16px;

	}

}