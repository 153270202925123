#classifiers {

  min-height: 90%;
  height: 90%;

  #tbody-loader {


    .uil-rolling-css {

      min-height: 300px;
      margin: 5% auto 0 auto;

    }

  }

  .ng-isolate-scope:first-child{

    //height: 100%;

    .tab-content{

      height: 100%;

    }

  }

  table{

    thead {

      th {

        .btn {

          padding: 3px 10px;
          font-size: 15px;

        }

      }

    }

    tbody {

      td > .error-spacing {

        overflow:hidden;
        height:60px;

      }

    }

  }

}
