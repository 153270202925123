.calendar {

	&-event {

		margin-bottom: 5px;
		padding: 4px 8px;
		border-radius: 3px;
		font-size: 12px;
		color: #000;
		display: block;

		&-red {

			background: lighten(@brand-primary,40%);

			&:hover {

				background: lighten(@brand-primary,35%);
				color: darken(@brand-primary,35%);

			}

		}

		&-blue {

			background: lighten(@brand-info,30%);

			&:hover {

				background: lighten(@brand-info,25%);
				color: darken(@brand-info,35%);

			}

		}

		&-green {

			background: lighten(#6EE340,30%);

			&:hover {

				background: lighten(#6EE340,25%);
				color: darken(#6EE340,35%);

			}

		}

		&-yellow {

			background: lighten(#EDEA28,25%);

			&:hover {

				background: lighten(#EDEA28,20%);
				color: darken(#EDEA28,35%);

			}

		}

		&-purple {

			background: lighten(#C390D4,20%);

			&:hover {

				background: lighten(#C390D4,15%);
				color: darken(#C390D4,35%);

			}

		}

		&-brown {

			background: lighten(#735E32,40%);

			&:hover {

				background: lighten(#735E32,35%);
				color: darken(#735E32,35%);

			}

		}


		&-completed {

			background: darken(#fff,10%);
			color: #ccc;

			&:hover {

				background: darken(#fff,15%);
				color: darken(#ccc,30%);

			}

		}

	}

	&-header {

		text-align: center;
		margin-top: 30px;
		font-size: 20px;
		line-height: 62px;

		&-headline {

			font-size: 40px;
			display: inline-block;
			margin: 0 20px;
			line-height: 62px;
			vertical-align: middle;

		}

	}

	&-day {

		&-disabled {

			background: darken(#fff,10%);

		}

		&-number {

			width: 30px;
			height: 30px;
			border-radius: 100px;
			background: darken(#fff,4%);
			display: inline-block;
			text-align: center;
			font-weight: bold;
			line-height: 30px;
			color: darken(#fff,30%);
			margin-bottom: 15px;

		}

		&-today {

			&-active {

				background: @brand-primary;

				.calendar-day-number {

					background: #fff;
					color: @brand-primary;

				}

			}

		}

		&-weekend {

			background: darken(#ffffff,5%);

			.calendar-day-number {

				background: darken(#fff, 10%);

			}

		}

	}

}