body {

  background-color: @body-bg;

}

label {

  font-weight: normal;

}

strong,
.bold {

  font-weight: 700;

}
