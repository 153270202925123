.row {

	.col-5ths {

		width: 20%;
		float: left;

	}

	&-glued {

		margin: 0;

		[class*="col-"] {

			padding: 0;

			& + [class*="col-"] {

				.panel {

					margin-left: -1px;

				}

			}

			.panel {

				&-default,
				&-heading {

					border-radius: 0;

				}

			}

			&:first-child {

				.panel {

					&-default,
					&-heading {


						border-top-left-radius: 3px;
						border-bottom-left-radius: 3px;

					}

				}

			}

			&:last-child {

				.panel {

					&-default,
					&-heading {

						border-top-right-radius: 3px;
						border-bottom-right-radius: 3px;

					}

				}

			}

		}

	}

}