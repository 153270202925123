@import "../../../node_modules/ol/ol.css";
@import "../../../node_modules/ui-select/dist/select.css";

@import "bootstrap/less/bootstrap";
@import "bootstrap-material-design/less/bootstrap-material-design.less";
@import "bootstrap-material-design/less/ripples.less";
@import "mdi/css/materialdesignicons.min.css";
@import "custom/variables";
@import "custom/colors";
@import "custom/redesign";


@import "partials-old/jumbotron/index";
@import "partials-old/autocomplete/index";
@import "partials-old/hidden/index";
@import "partials-old/dl/index";
@import "partials-old/parameters/index";
@import "partials-old/table/index";
@import "partials-old/link/index";
@import "partials-old/sort/index";
@import "partials-old/form/index";
@import "partials-old/datepicker/index";
@import "partials-old/row/index";
@import "partials-old/on-off/index";
@import "partials-old/checkbox-btn/index";
@import "partials-old/dropdown/index";
@import "partials-old/pagination/index";
//@import "partials-old/contenteditable/index";
@import "partials-old/help/index";
@import "partials-old/append/index";
@import "partials-old/height/index";
@import "partials-old/areal/index";
@import "partials-old/vertical/index";
@import "partials-old/calendar/index";
@import "partials-old/calendar/month/index";
@import "partials-old/calendar/week/index";
@import "partials-old/alerts/index";

@import "partials/fonts/index";
@import "partials/body/index";
@import "partials/layout/index";
@import "partials/tabs/index";
@import "partials/modal/index";
@import "partials/select/index";
//@import "partials/btn/index";
@import "partials/navbar/index";
@import "partials/validation/index";
@import "partials/toggle/index";
@import "partials/table-filter/index";
//@import "partials/panel/index";
@import "partials/cf-action-form-member/index";
@import "partials/cf-class-td-btns/index";
@import "partials/cf-stores-td-btns/index";
@import "partials/cf-farm-import-shp/index";
@import "partials/cf-por-batch/index";
@import "partials/cf-farm-import-license/index";
@import "partials/cf-datepicker/index";
@import "partials/cf-navbar/index";

@import "partials/popups/index";

@import "pages/index";
@import "pages/parcels/index";
@import "pages/parcel/index";
@import "pages/zone/index";
@import "pages/zones/index";
@import "pages/stores/index";
@import "pages/actions/index";
@import "pages/por/index";
@import "pages/manager/index";
@import "pages/map/index";

h1,
.h1 {
  margin-bottom: 30px;
  margin-top: 10px;
  font-weight: 500;

  a[data-edit] {

    margin-left: 10px;
    font-size: 18px;

  }

  &.pull-left {

    margin-bottom: 0;
  }

}

img {

  max-width: 100%;
  height: auto;

}

.uppercase {

  text-transform: uppercase;

}

.large {

  font-size: 30px;

}

.no-todos {

  text-align: center;
  padding: 40px 20px;

  p {

    margin: 0;
    padding-top: 10px;

  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.valign-middle {
  display: table;
  width:100%
}

.valign-middle-row {
  float:none;
  display: table-cell;
  vertical-align: middle;
}

// calendar styling -- START

.tooltip.calendar .tooltip-inner {
  color: #000000;
  background-color: inherit;
  min-width: 600px;
  text-align: left;
}

.tooltip.calendar .tooltip-arrow {
  display: none;
}

.tooltip.calendar .tooltip-inner .calendar-action-sum-container {
  float: right;
}

.tooltip.calendar .tooltip-inner .calendar-action-sum-container .area {
  min-width: 125px;
  display: inline-block;
  text-align: right
}

p.calendar-menu {
  float: left;
}

.calendar-popup-head {
  line-height: 24px;
}

.calendar-popup-content .form-group {
  margin-bottom: 15px;
}

.calendar-event-confirmed {
  text-decoration: line-through;
}

.fc-sat, .fc-sun {
  background-color: #FFF2F2;
}

.fc-past {
  background-color: #EDEDED !important;
}

.fc-toolbar {
  margin-bottom: 0em;
}
.fc-toolbar h2:first-letter, th.fc-day-header {
  text-transform: capitalize;
}

.fc-center h2 {
  font-size: 1.7em
}

.fc-event {
  border: none;
  line-height: normal;
  font-size: 1em;
}

// calendar styling -- END

.classifiers-tabs {
  ul {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
}

.classifiers-heading{
  text-align: center;
}
