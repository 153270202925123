.map-popup{

  .leaflet-popup-content-wrapper{

    background: none;
    box-shadow: 0 0 0;
    padding: 0;
    top: 31px;
    position: relative;

  }

  .leaflet-popup-content{

    margin: 0;

  }

}