a.disabled {

  cursor: not-allowed;
  color: @gray-light;

  &:hover {

    text-decoration: none;

  }

}
