#actions{

  .parcels-operations,
  .actions-operations{
    margin: 21px 0px 14px 0px;

    .btn{

      margin: 0;

    }

    cf-datepicker{
      p{
        margin: 0;
        input{
          margin: 0;
        }
      }
    }

    .form-control{

      margin-bottom: 0;

    }
  }

  .loader {

    min-height: 350px;

    .uil-rolling-css {

      margin-top: 10%;

    }

  }

  .select-date{

    table{

      tr{

        td, th{

          padding: 0px!important;
          text-align: center;
          background: none;

        }

      }

    }

  }

  .nav-tabs{

    border-bottom: 0;

  }

  .tab-content{

    padding: 0;
    border-width: 0;

  }

  .table{

    &.table-actions-tab-devider{

      margin-bottom: 0px;

    }

    tbody:hover{

      background-color: @table-bg-hover;

    }

    th {

      background-color: transparent;

      .btn-group{

        margin-top: 0;
        margin-bottom: 0;

      }

      .dropdown{

        margin-right: 5px;

      }

      .single-button{

        margin-top: 0;

      }

    }

    tr {

      td.align-top{

        vertical-align: top !important;

      }

    }

  }
}

.actionsModal{



  .table{

    tbody:hover{

      background-color: @table-bg-hover;

    }

    tr {

      p{
        margin: 0;
        text-align: center;
      }

      .glyphicon-ok{
        color: #5CB85C;
      }

      .glyphicon-remove{
        color: #CB4D41;
      }

      .align-top{

        vertical-align: top !important;

      }
    }

  }

}

#actions
.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {

  background: #cb4d41 none repeat scroll 0 0;
  color: #ffffff;

}

