.link {

	&-add {

		.glyphicon {

			margin-right: 10px;
			top: 2px;
			position: relative;

		}

	}

}

