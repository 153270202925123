.toggle {

	cursor: pointer;

	&-box {

		display: none;

		&-select {

			position: absolute;
			left: -9999px;
			display: block;

			&.active {

				position: relative;
				left: 0;

			}
		}

	}

	&-sidebar {

		color: @sidebar-text;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60px;
		text-align: center;
		line-height: 60px;
		background-color: @sidebar-background;

		&:active,
		&:focus {

			color: @sidebar-text;

		}

		&:hover {

			color: @sidebar-text-hover;

		}

	}

}