.new-parcel-popup {

  .col-md-12{

    p {

      padding: 7px;
      margin: 0;
      font-size: 18px;

    }

  }

  cf-datepicker {

    p {

      margin: 0;

    }

  }

}