.vertical {

	display: table;
	width: 100%;

	&-middle {

		display: table-cell;
		vertical-align: middle;
		width: 100%;

	}
}