.dropdown {

	position: relative;

	&-menu {

		li:first-child {

			.dropdown-value {

				border-top: 0;

			}

		}

	}

	&-value {

		display: block;
		padding: 5px 10px;
		clear: both;
		border-top: 1px solid #f7f7f7;

		strong {

			float: right;
			margin-left: 20px;

		}

	}

	&-trigger {

		cursor: pointer;

		&:hover {

			color: #000;

			span {

				color: @brand-primary;
			}

		}

		span {

			float: right;
			position: relative;
			top: 7px;
			font-size: 12px;

		}
	}

	&-list {

		position: absolute;
		top: 100%;
		left: 0;
		background: #fff;
		border: 1px solid #ccc;
		border-top: 0;
		width: 100%;
		list-style: none;
		padding: 0;
		border-radius: 4px;
		margin: 5px 0 0;

		> li {

			&:first-child {

				border-radius: 4px 4px 0 0;

			}

			li {

				&:last-child {

					border-radius: 0 0 4px 4px;

				}

			}

		}

		&-item {

			border-top: 1px solid #ccc;

			label {

				display: block;
				padding: 7px 15px;
				font-size: 14px;
				margin: 0;

				&:hover {

					color: #000;
					cursor: pointer;

				}

				input {

					margin-right: 5px;

				}

			}

		}

		&-sub {

			list-style: none;
			padding: 0;
			margin: 0;
			background: #f7f7f7;

			&:last-child {

				border-radius: 0 0 4px 4px;

			}

			.dropdown-list-item {

				label {

					padding: 7px 10px 7px 30px;

				}

			}

		}

	}

	&-open {

		.dropdown-trigger {

			border-radius: 4px 4px 0 0;
			border-bottom-color: #fff;

		}

	}

}